import { ADMIN_IMAGES } from '@dbh/images-redux';

const SOURCE_SYSTEM={daybreakHero:"WL",default:"W1"};const ID_PAYMENT_METHOD_VALUES=Object.freeze({PAY_AT_HOTEL:1,BRAINTREE:2});const BOOKING_FORM_INPUT_NAMES=Object.freeze({BRAINTREE_WIDGET_SECTION_VISIBLE:"braintreeWidgetSectionVisible",DBH_CC_SECTION_VISIBLE:"dbhCCSectionVisible",BRAINTREE_PAYMENT_METHOD_NONCE:"braintreePaymentMethodNonce",BRAINTREE_PAYMENT_DEVICE_DATA:"deviceData",BRAINTREE_THREE_D_SECURE_AUTHENTICATION_ID:"threeDSecureAuthenticationId",BRAINTREE_CUSTOMER_ID:"braintreeCustomerId",ENABLED_PAYMENT_METHODS:"enabledPaymentMethods",ID_PAYMENT_METHOD:"idPaymentMethod",PAYMENT_METHOD_CHOICE_SECTION_VISIBLE:"paymentMethodChoiceSectionVisible",EMAIL:"email",FIRST_NAME:"firstName",LAST_NAME:"lastName",PHONE_NUMBER:"phoneNumber",TERMS_AND_CONDITIONS_ACCEPTED:"termsAndConditionsAccepted",ID_DISCOUNT_TYPE:"idDiscountCodeType",ESTIMATED_ARRIVAL_TIME:"estimatedArrivalTime",ID_CREDIT_CARD_TYPE:"idCreditCardType",CREDIT_CARD_NUMBER:"creditCardNumber",CREDIT_CARD_EXPIRE_MONTH:"creditCardExpireMonth",CREDIT_CARD_EXPIRE_YEAR:"creditCardExpireYear",CREDIT_CARD_HOLDER_NAME:"creditCardHolderName",CREDIT_CARD_EXPIRY_MONTH_YEAR_DATE:"expiryMonthYearDate",DISALLOW_RATING:"disallowRating",DISALLOW_NEWSLETTERS:"disallowNewsletters"});const IDMEDIUM_ID_OTHER="9";const IDMEDIUM_ID_GOOGLE="1";const translationIds={where:"components.BookingForm.select_where_you_find_us",payNow:"components.BookingFormTitle.title",onRequest:"components.BookingFormTitle.title_on_request",relaxOrWork:"components.BookingForm.select_leisure_or_work",allowNewsLetters:"components.BookingForm.credit_card.newsletter",spinner:"components.BookingForm.spinner_loading",// Shown when for bookings without `onRequest` and "Pay at the hotel" has
// been selected
buttonPayAtHotel:"components.BookingForm.tappable_button",// Shown when the `braintree` ("Pay Online") payment widget has been selected.
buttonBook:"components.BookingForm.tappable_button_book",// Shown when the booking has `onRequest` true.
buttonOnRequest:"components.BookingForm.tappable_button_on_request",termsSilentAccept:"components.BookingForm.credit_card.terms_conditions_silent_accept",bannerUserTitle:"components.BookingForm.BannerUser.signin_or_signup",bannerUserMessage:"components.BookingForm.BannerUser.become_member",paymentMethodTranslationIds:{paymentMethodChoice:"components.BookingForm.payment_method_choice",payAtHotel:"components.BookingForm.payment.pay_at_hotel",payAtHotelWithCreditCard:"components.BookingForm.payment.pay_at_hotel_cc_necessary",payOnline:"components.BookingForm.payment.pay_online"},braintreeTranslationIds:{creditCardVerificationTitle:"components.BookingForm.credit_card.title",creditCardVerificationDescription:"components.BookingForm.credit_card.description",titleBraintreePayment:"components.BookingForm.payment.braintree.title",brainTreeLoadingTokenError:"components.BookingForm.braintree_payment.loading_token_error",reloadBrainTreeToken:"components.BookingForm.braintree_payment.reload_token"},// Shared `translationIds`
tappableSignin:"components.FormSignIn.tappable_signin",tappableCreate:"components.FormSignUp.tappable_create",// discount form translation ids
title:"components.BookingForm.discounts_points.title",discountCode:"components.BookingForm.discounts_points.input_discount_code",apply:"components.BookingForm.discount_apply",remove:"components.BookingForm.discount_remove",trenitaliaCode:"components.BookingForm.discounts_points.input_cartafreccia",verificationCode:"components.BookingForm.details.verification_code"};const relaxOrWorkOptions=[{id:"W",label:"components.BookingForm.select_leisure_or_work.work"},{id:"R",label:"components.BookingForm.select_leisure_or_work.relax"}];const CONTAINING=["100%","100%","100%","1180px"];const FORM_VARIANT_STANDARD_DISPLAY_OPTIONS=Object.freeze({showNewsletter:!0,showUserBanner:!0,showForHotel:!0,showDiscount:!0,showMedium:!1,showRelaxOrWorkQuestion:!0,showFormSectionLine:!0,useGenericTranslations:!1});const FORM_VARIANT_LUGGAGE_DISPLAY_OPTIONS=Object.freeze({showNewsletter:!1,showUserBanner:!1,showForHotel:!1,showDiscount:!1,showMedium:!1,showRelaxOrWorkQuestion:!1,showFormSectionLine:!1,useGenericTranslations:!0});const TRENITALIA_PARTNERSHIP=Object.freeze({id:2,fieldName:"trenitaliaCode",logoImageReference:ADMIN_IMAGES.CARTAFRECCIA,placeholder:translationIds.trenitaliaCode,visible:!1});const PAYMENT_VISIBILITY_FIELDS_NAMES=[BOOKING_FORM_INPUT_NAMES.BRAINTREE_WIDGET_SECTION_VISIBLE,BOOKING_FORM_INPUT_NAMES.PAYMENT_METHOD_CHOICE_SECTION_VISIBLE,BOOKING_FORM_INPUT_NAMES.DBH_CC_SECTION_VISIBLE];const FIELDS_TO_BLUR=[BOOKING_FORM_INPUT_NAMES.EMAIL,BOOKING_FORM_INPUT_NAMES.FIRST_NAME,BOOKING_FORM_INPUT_NAMES.LAST_NAME,BOOKING_FORM_INPUT_NAMES.PHONE_NUMBER,BOOKING_FORM_INPUT_NAMES.TERMS_AND_CONDITIONS_ACCEPTED,BOOKING_FORM_INPUT_NAMES.ESTIMATED_ARRIVAL_TIME,BOOKING_FORM_INPUT_NAMES.ID_DISCOUNT_TYPE];const BOOKING_FORM_FIELD_NAMES_FOR_THREE_D_SECURE_PARAMETERS=[BOOKING_FORM_INPUT_NAMES.FIRST_NAME,BOOKING_FORM_INPUT_NAMES.LAST_NAME,BOOKING_FORM_INPUT_NAMES.PHONE_NUMBER,BOOKING_FORM_INPUT_NAMES.EMAIL];

export { BOOKING_FORM_FIELD_NAMES_FOR_THREE_D_SECURE_PARAMETERS, BOOKING_FORM_INPUT_NAMES, CONTAINING, FIELDS_TO_BLUR, FORM_VARIANT_LUGGAGE_DISPLAY_OPTIONS, FORM_VARIANT_STANDARD_DISPLAY_OPTIONS, IDMEDIUM_ID_GOOGLE, IDMEDIUM_ID_OTHER, ID_PAYMENT_METHOD_VALUES, PAYMENT_VISIBILITY_FIELDS_NAMES, SOURCE_SYSTEM, TRENITALIA_PARTNERSHIP, relaxOrWorkOptions, translationIds };
