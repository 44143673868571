import { composeThemeModificationsFunctions } from '@dbh/styled-components-extra';

/*
 *
 * Theme: `Menu`.
 *
 */const THEME_KEY_AUTOCOMPLETE_MENU="autocomplete/menu";const modifyMenuTheme=a=>{let{fonts:{size:{ff7:e}},spacing:{sp10:b,sp1:c,sp9:d}}=a;return {[THEME_KEY_AUTOCOMPLETE_MENU]:{fontSize:e,bottomForDropUp:d,maxHeight:"calc(100vh - "+b+")",marginTop:c,minWidth:"100%"}}};var modifyMenuTheme$1 = modifyMenuTheme;

/*
 *
 * Theme: `Item`.
 *
 */const THEME_KEY_AUTOCOMPLETE_ITEM="autocomplete/item";const modifyItemTheme=a=>{let{fonts:{size:{ff7:f},lineHeight:{l2:g}},spacing:{sp6:b,sp13:c},colors:{brandSecondLight:d,blackMid:e}}=a;return {[THEME_KEY_AUTOCOMPLETE_ITEM]:{fontSize:f,padding:"0 "+b,height:c,lineHeight:g,highlightedColor:d,color:e}}};var modifyItemTheme$1 = modifyItemTheme;

const modifyAutocompleteTheme=composeThemeModificationsFunctions(modifyMenuTheme$1,modifyItemTheme$1);

export { THEME_KEY_AUTOCOMPLETE_ITEM, THEME_KEY_AUTOCOMPLETE_MENU, modifyAutocompleteTheme as default };
