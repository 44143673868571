import _noop from 'lodash/noop';
import 'prop-types';
import 'react-immutable-proptypes';
import '@dbh/admindev-constants';

const idSearchTypePropType=_noop;const paymentMethodPropType=_noop;const timeSlotPropType=_noop;/**
 * @typedef {string} PackageGroupReference `admindev` reference field
 * of a package group entry. Belongs to the `admindev` "Navigation menu" called:
 * `package-categories-groups`.
 */const packageGroupReferencePropType=_noop;/**
 * @typedef {string} MacroCategoryReference @example `RO`. Look at `parentReference`.
 * @typedef {Immutable.List<MacroCategoryReference>} MacroCategoryReferenceList .
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/packagecategories}
 */const macroCategoriesReferencePropType=_noop;const pageTypePropType=_noop;/**
 * @typedef {string} AdminPageReference
 */const adminPageReferencePropType=_noop;const reservationStatusPropType=_noop;const listOfMacroCategoriesImmutablePropType=_noop;/**
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/packagecategories}
 * @typedef {string} MacroCategoryDisplayText
 * @typedef {Immutable.List<MacroCategoryReference, MacroCategoryDisplayText>} MacroCategoryTuples
 */

export { adminPageReferencePropType, idSearchTypePropType, listOfMacroCategoriesImmutablePropType, macroCategoriesReferencePropType, packageGroupReferencePropType, pageTypePropType, paymentMethodPropType, reservationStatusPropType, timeSlotPropType };
