import 'prop-types';
import { ensureGtag } from '@dbh/tracking-gtag-shared-with-browser';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _pickBy from 'lodash/pickBy';
import '@dbh/search-context-types';
import { abbreviatePlaceId } from '@dbh/get-ga-search-term-value-from-search-context';

/**
 * Sends `GA4` `user_id` with `gtag`.
 * @param {number?} userId .
 * @see {@link https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id}
 */const gtagConfigUserId=withConformsTo("gtagConfigUserId",[],a=>{ensureGtag(),window.gtag("set","user_id",a);});var gtagConfigUserId$1 = gtagConfigUserId;

/*
 *
 * `isAnalyticsQueryParameter`: `@dbh/analytics`.
 *
 */const utmRegex=/utm/i,isAnalyticsQueryParameter=a=>"string"==typeof a&&("gclid"===a||utmRegex.test(a));/**
 * Is the input an "Analytics" related key (@example `utm_source`).
 * @param {string} key Query parameter key to check.
 * @return {boolean} Is `key` an "Analytics" related key.
 */var isAnalyticsQueryParameter$1 = isAnalyticsQueryParameter;

/**
 * Given a `req.query` like object, returns a subset of all its "Analytics"
 * related keys (@example `utm_source`).
 * @param {Object?} query Mapping between query keys and query parameter values.
 * @return {Object} Subset of all the "Analytics" related keys of the `query`.
 * @see {@link https://expressjs.com/en/4x/api.html#req.query}
 */const pickAnalyticsQueryParams=withConformsTo("pickAnalyticsQueryParams",[],a=>a?_pickBy(a,(a,b)=>isAnalyticsQueryParameter$1(b)):{});var pickAnalyticsQueryParams$1 = pickAnalyticsQueryParams;

/**
 * Given a search context, returns `GA4` event parameters. Most of them are also
 * created as custom dimensions or metrics in the `GA4` dashboard, but not all
 * of them.
 * @param {Immutable.Map} searchContext .
 * @return {Object} .
 * @see {@link https://analytics.google.com/analytics/web/?authuser=1#/a39589072p344388821/admin/customdefinitions/hub}
 * @see {@link https://analytics.google.com/analytics/web/?authuser=1#/a39589072p326658850/admin/customdefinitions/hub}
 */const searchContextToGaEventParameters=withConformsTo("searchContextToGaEventParameters",[],a=>{var b;const c=null==(b=a.getIn(["filters","idServices"]))?void 0:b.join(","),d=a.getIn(["location","placeId"]);return {idProductsQuery:a.get("idProductsQuery"),searchDate:a.get("searchDate"),idSearchEntity:a.getIn(["location","idSearchEntity"]),idSearchType:a.getIn(["location","idSearchType"]),searchText:a.getIn(["location","searchText"]),priceMax:a.getIn(["filters","priceMax"]),priceMin:a.getIn(["filters","priceMin"]),starsMax:a.getIn(["filters","starsMax"]),starsMin:a.getIn(["filters","starsMin"]),checkIn:a.getIn(["filters","checkIn"]),checkOut:a.getIn(["filters","checkOut"]),distance:a.getIn(["filters","distance"]),idServices:c,orderDirection:a.getIn(["order","direction"]),orderField:a.getIn(["order","field"]),// `placeId` is not created as a custom dimension in the `GA4` dashboard.
...(d?{placeId:abbreviatePlaceId(d)}:{}),isNoResults:"false"}});var searchContextToGaEventParameters$1 = searchContextToGaEventParameters;

export { gtagConfigUserId$1 as gtagConfigUserId, isAnalyticsQueryParameter$1 as isAnalyticsQueryParameter, pickAnalyticsQueryParams$1 as pickAnalyticsQueryParams, searchContextToGaEventParameters$1 as searchContextToGaEventParameters };
