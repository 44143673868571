/*
 *
 * Package: `@dbh/add-to-cart-card-theme`.
 *
 */const THEME_KEY_ADD_TO_CART_CARD="addToCartCard";const modifyAddToCartCardTheme=a=>{let{spacing:{sp2:b,sp5:c,sp6:d,sp8:e,sp11:f,sp4:g,sp19:h},colors:{greyUltralight:i,brandFifth:j},fonts:{style:{medium:k}}}=a;const l=h,m=h,n=f,o=c,p=f,q=g,r="calc("+q+" + "+b+")",s=g;return {[THEME_KEY_ADD_TO_CART_CARD]:{card:{selectedBorder:"1px solid "+j,// if `showBottomPriceBar` prop is true you should add `bottomPriceHeight` manually.
gridTemplateRows:l+" calc("+n+" + "+r+")",// if `showBottomPriceBar` prop is true you should add `totalPriceBarHeight` manually.
height:"calc("+n+" + "+l+" + "+r+")",boxShadow:"0px 0px 6px -1px rgba(0, 0, 0, 0.2)"},priceLabelWrapper:{borderTop:"1px solid "+i,padding:s+" "+o,height:"calc("+e+" + "+s+")"},cardContent:{padding:q+" "+o},selectedLabel:{backgroundColor:j,minWidth:d,padding:b,weight:k},addIndicatorWrapper:{top:"calc("+l+" - "+p+" / 2)",right:c},desktop:{card:{// if `showBottomPriceBar` prop is true you should add `bottomPriceHeight` manually.
gridTemplateRows:m+" calc("+n+" + "+r+")",// if `showBottomPriceBar` prop is true you should add `totalPriceBarHeight` manually.
height:"calc("+n+" + "+m+" + "+r+")"},addIndicatorWrapper:{top:"calc("+m+" - "+p+" / 2)"}}}}};

export { THEME_KEY_ADD_TO_CART_CARD, modifyAddToCartCardTheme as default };
