import 'react-immutable-proptypes';
import { BOOKING_FORM_INPUT_NAMES, TRENITALIA_PARTNERSHIP, ID_PAYMENT_METHOD_VALUES, SOURCE_SYSTEM, FORM_VARIANT_STANDARD_DISPLAY_OPTIONS, FORM_VARIANT_LUGGAGE_DISPLAY_OPTIONS } from '@dbh/booking-form-constants';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { memoize } from '@dbh/lodash-extra';
import emailValidator from 'email-validator';
import { List, Map, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { ENABLED_PAYMENT_METHODS, PAYMENT_METHODS_REQUIRING_CC_VALUES } from '@dbh/admindev-constants';
import { mapContainsExactImmutablePropType } from '@dbh/generic-types';
import '@dbh/navigation-history-redux';
import '@dbh/user-area-redux';
import '@dbh/admindev-types';
import '@dbh/shopping-carts-types';
import format from 'date-fns/format';
import subMinutes from 'date-fns/subMinutes';
import { makeTimeSlotOptions } from '@dbh/select-field';
import { BOOKING_FORM_VARIANTS } from '@dbh/booking-form-page-constants';
import '@dbh/booking-types';
import { TEST_EMAIL } from '@dbh/development-redux';

/**
 * Converts an immutable `Map` of `BookingForm` values to be compatible with
 * the back end `API`.
 * @param {Immutable.Map} immutableBookingFormValues `redux-form` values.
 * @return {Immutable.Map} Adapted form values to be sent to the `API`.
 * @see {@link https://api.daybreakhotels.com/v0.1/IT/it-IT/bookings}
 */const makeBookingsApiValuesFromBookingFormValues=withConformsTo("makeBookingsApiValuesFromBookingFormValues",[],a=>a.withMutations(b=>{const c=!a.get("disallowNewsletters"),d=a.get("idPaymentMethod"),e=!a.get("disallowRating"),f=!a.get("discountCode");// Do not pass an empty string: it causes an `API` form validation error.
return f&&b.delete("discountCode"),b.deleteAll([BOOKING_FORM_INPUT_NAMES.DISALLOW_NEWSLETTERS,BOOKING_FORM_INPUT_NAMES.DISALLOW_RATING,BOOKING_FORM_INPUT_NAMES.BRAINTREE_WIDGET_SECTION_VISIBLE,BOOKING_FORM_INPUT_NAMES.PAYMENT_METHOD_CHOICE_SECTION_VISIBLE,BOOKING_FORM_INPUT_NAMES.DBH_CC_SECTION_VISIBLE,BOOKING_FORM_INPUT_NAMES.ENABLED_PAYMENT_METHODS,BOOKING_FORM_INPUT_NAMES.CREDIT_CARD_EXPIRY_MONTH_YEAR_DATE]).merge({idPaymentMethod:d,// "`disallowNewsletters` in the `UI` is "I do not want to be sent newsletters
// and offers", but we need to send it to the `API` as `allowNewsLetters`.
allowNewsletters:c,// We invert the value because the checkbox, when checked, currently means
// "no, i do not want to be sent rating email"
allowRating:e})}));var makeBookingsApiValuesFromBookingFormValues$1 = makeBookingsApiValuesFromBookingFormValues;

const{FIRST_NAME: FIRST_NAME$1,LAST_NAME: LAST_NAME$1,EMAIL: EMAIL$1,PHONE_NUMBER,TERMS_AND_CONDITIONS_ACCEPTED,ID_DISCOUNT_TYPE,ESTIMATED_ARRIVAL_TIME}=BOOKING_FORM_INPUT_NAMES,getRequiredFields=memoize(a=>{// These fields are always required.
let b=[FIRST_NAME$1,LAST_NAME$1,EMAIL$1,TERMS_AND_CONDITIONS_ACCEPTED];if(!a)return [];const c=a.get(ID_DISCOUNT_TYPE),d=a.get("mandatoryCustomerPhone"),e=a.get("mandatoryArrivalTime");// Required (text input) fields if the user has selected a discount code card.
if(d&&b.push(PHONE_NUMBER),e&&b.push(ESTIMATED_ARRIVAL_TIME),c){const{fieldName:a}=TRENITALIA_PARTNERSHIP;b=[...b,a];}return b});/**
 * Gets "booking form" required fields from `initialValues`.
 * @param {Immutable.Map?} values The form `initialValues`.
 * @return {string[]} Contains required field names.
 */var getRequiredFields$1 = getRequiredFields;

const{FIRST_NAME,LAST_NAME,EMAIL}=BOOKING_FORM_INPUT_NAMES,translationIdGlobalFieldInvalid="global.forms.field_invalid",syncValidate=a=>{const b=a.get("idDiscountCodeType"),c=a.get("idPaymentMethod");// These fields are always required.
let d={firstName:a.get(FIRST_NAME),lastName:a.get(LAST_NAME),email:a.get(EMAIL),termsAndConditionsAccepted:a.get("termsAndConditionsAccepted")};// Required (text input) fields if the user has selected a discount code card.
if(b){const{fieldName:b}=TRENITALIA_PARTNERSHIP;d={...d,[b]:a.get(b)};}const e={};// Basic validation checking that the field is truthy.
Object.keys(d).forEach(a=>{const b=d[a];b||(e[a]=translationIdGlobalFieldInvalid);});return !c&&(e.idPaymentMethod="components.BookingForm.payment.empty_validation_message"),e.email||emailValidator.validate(d.email)||(e.email=translationIdGlobalFieldInvalid),e};var syncValidate$1 = syncValidate;

/**
 * @typedef {Immutable.Map} AttributionData Contains `utm_*` query parameters
 * values; only valid, string values.
 * @property {string?} source .
 * @property {string?} medium .
 * @property {string?} campaign .
 * @typedef {string|Immutable.List<string>} UtmValueFromUrl Value of an `utm_*`
 * query parameter in the `URL`; it may be not valid (not string) or malformed.
 * @typedef {Immutable.Map<string,UtmValueFromUrl>} UtmValuesFromUrl All `utm_*`
 * query parameters in the `URL`.
 *//**
 * Given the dictionary of `UTM` query parameters taken directly from the `URL`,
 * it extracts the value of the parameter with the given name (`key`).
 * @param {UtmValuesFromUrl} utmValues .
 * @param {string} key Query parameter name in the `URL`. Get the value, if valid.
 * @return {string?} Value of the query parameter.
 */const extractUtmValue=withConformsTo("extractUtmValue",[],(a,b)=>{let c=a.get(b);// It is technically possible, even though unlikely, that in the `URL`
// one or more `utm_*` parameters have multiple values assigned to them.
// It is an explicit business requirement to pick the **last** one.
// It is also technically possible, even though unlikely, that the value is
// a number and not a string, in that case we ignore it.
return List.isList(c)&&0<c.size&&(c=c.last()),"string"==typeof c?c:void 0}),createAttributionData=withConformsTo("createAttributionData",[],a=>{if(!Map.isMap(a))return;const b=extractUtmValue(a,"utm_source"),c=extractUtmValue(a,"utm_campaign"),d=extractUtmValue(a,"utm_medium"),e=extractUtmValue(a,"utm_term"),f=extractUtmValue(a,"utm_content");return b||d||c||e||f?Map({source:b,medium:d,campaign:c,term:e,content:f}):void 0});/**
 * Given the dictionary of `UTM` query parameters taken directly from the `URL`,
 * it returns a subset, including only selected and valid parameters.
 * @param {UtmValuesFromUrl?} utmValues .
 * @return {AttributionData?} `undefined` if the input is not valid.
 */var createAttributionData$1 = createAttributionData;

/**
 * Returns the payment method `ID` to send to the back end, given the enabled
 * payment methods.
 * @param {string} enabledPaymentMethods .
 * @return {number} .
 */const getInitialIdPaymentMethod=withConformsTo("getInitialIdPaymentMethod",[],a=>{const b=a===ENABLED_PAYMENT_METHODS.PREPAID;return b?ID_PAYMENT_METHOD_VALUES.BRAINTREE:ID_PAYMENT_METHOD_VALUES.PAY_AT_HOTEL});var getInitialIdPaymentMethod$1 = getInitialIdPaymentMethod;

/**
 * @typedef {import('@dbh/shopping-carts-types').ShoppingCart} ShoppingCart
 *//**
 * Returns `true` if the credit card widget should be visible.
 * @param {ShoppingCart} shoppingCart .
 * @param {number} idPaymentMethod .
 * @return {boolean} .
 */const shouldShowDbhCCSection=withConformsTo("shouldShowDbhCCSection",[],(a,b)=>{const c=a.getIn(["hotel","pushCreditCardInfo"]);if(!c)return !1;const d=a.getIn(["bookingAttributes","enabledPaymentMethods"]);return !(d!==ENABLED_PAYMENT_METHODS.CREDIT_CARD_AS_GUARANTEE)||d===ENABLED_PAYMENT_METHODS.PREPAID_OR_CREDIT_CARD_AS_GUARANTEE&&b===ID_PAYMENT_METHOD_VALUES.PAY_AT_HOTEL});var shouldShowDbhCCSection$1 = shouldShowDbhCCSection;

/**
 * @typedef {import('@dbh/shopping-carts-types').ShoppingCart} ShoppingCart
 *//**
 * Returns `true` if the `braintree` widget should be visible based on the
 * enabled payment method.
 * @param {ShoppingCart} shoppingCart .
 * @param {number} idPaymentMethod .
 * @return {boolean} .
 */const shouldShowBraintreeWidgetSection=withConformsTo("shouldShowBraintreeWidgetSection",[],(a,b)=>{const c=shouldShowDbhCCSection$1(a,b);if(c)return !1;const d=a.getIn(["bookingAttributes","enabledPaymentMethods"]);return !!PAYMENT_METHODS_REQUIRING_CC_VALUES.includes(d)||d===ENABLED_PAYMENT_METHODS.ALL&&b===ID_PAYMENT_METHOD_VALUES.BRAINTREE});var shouldShowBraintreeWidgetSection$1 = shouldShowBraintreeWidgetSection;

/**
 * Creates and returns the `BookingForm` `redux-form` initial values.
 * @param {Immutable.Map?} options.shoppingCart .
 * @param {string} options.anonymousId .
 * @param {Immutable.Map?} options.user .
 * @param {Immutable.Map?} options.utmValues .
 * @param {boolean?} options.isDaybreakHeroSite .
 * @return {Immutable.Map?} Pass this to `redux-form` as `initialValues`.
 */const createInitialValues=memoize(withConformsTo("createInitialValues",[],a=>{let{shoppingCart:b,anonymousId:c,user:d,utmValues:e,gpdrConsent:f,navigationHistory:g,isDaybreakHeroSite:h}=a;if(!b)return;const i=b.get("bookingAttributes");if(!i)return;const j=b.get("idShoppingCart"),k=b.get("guidShoppingCart"),l=b.get("day"),m=i.get("onRequest"),n=i.get("mandatoryCustomerPhone"),o=i.get("mandatoryArrivalTime"),p=i.get("enabledPaymentMethods");let q,r,s,t,u=!1;// Default value for non signed in users.
d&&(q=d.get("firstName"),r=d.get("lastName"),s=d.get("phoneNumber"),t=d.get("email"),u=!d.get("allowNewsletters"));const v=getInitialIdPaymentMethod$1(p),w=shouldShowDbhCCSection$1(b,v),x=[ENABLED_PAYMENT_METHODS.ALL,ENABLED_PAYMENT_METHODS.PREPAID_OR_CREDIT_CARD_AS_GUARANTEE].includes(p),y=shouldShowBraintreeWidgetSection$1(b,v),z=createAttributionData$1(e),A=Map({attribution:z,gpdrConsent:f,navigationHistory:g});// We use `Map` beacuse they are all primitive values, no need for the deep
// immutable conversion that `fromJS` does.
return Map({onRequest:m,mandatoryCustomerPhone:n,mandatoryArrivalTime:o,// 'termsAndConditionsAccepted' is required by the `API`, but the checkbox
// is hidden in the `BookingForm` (business logic).
// @see `component/BookingForm`.
termsAndConditionsAccepted:!0,idShoppingCart:j,guidShoppingCart:k,anonymousId:c,sourceSystem:h?SOURCE_SYSTEM.daybreakHero:SOURCE_SYSTEM.default,discountCode:"",firstName:q,lastName:r,phoneNumber:s,email:t,disallowNewsletters:u,braintreeWidgetSectionVisible:y,dbhCCSectionVisible:w,paymentMethodChoiceSectionVisible:x,enabledPaymentMethods:p,idPaymentMethod:v,[BOOKING_FORM_INPUT_NAMES.BRAINTREE_PAYMENT_METHOD_NONCE]:void 0,[BOOKING_FORM_INPUT_NAMES.BRAINTREE_THREE_D_SECURE_AUTHENTICATION_ID]:void 0,[BOOKING_FORM_INPUT_NAMES.BRAINTREE_PAYMENT_DEVICE_DATA]:void 0,tracking:A,day:l,disallowRating:!!h})}));var createInitialValues$1 = createInitialValues;

// arrivo indicativo") in the `BookingForm`.
const makeSelectEstimatedArrivalTimeFieldOptions=(a,b,c)=>{const d=a.get("checkInTime"),e=a.get("checkOutTime");if(!d||!e)return;const[f,g]=e.split(":"),h=new Date(c);h.setHours(f,g,0,0);// The arrival time should be less than the checkout time by an hour at maximum.
const i=subMinutes(h,b),j=format(i,"HH:mm");return makeTimeSlotOptions(b,d,j,{optionsDate:new Date(c),nextDayTimeFormat:"HH:mm (dd/MM/yyyy)"})};var makeSelectEstimatedArrivalTimeFieldOptions$1 = makeSelectEstimatedArrivalTimeFieldOptions;

const BOOKING_FORM_VARIANTS_VALUES=Object.values(BOOKING_FORM_VARIANTS);/**
 * @typedef {('LUGGAGE'|'STANDARD')} BookingFormVariants .
 * @typedef {Object} FormDisplayOptions .
 * @property {boolean} showNewsletter Show the checkbox to receive the newsletter.
 * @property {boolean} showUserBanner Show the banner that prompts the user to
 * sign up.
 * @property {boolean} showForHotel Show the field to ask questions to the hotel.
 * @property {boolean} showDiscount Show the discount field.
 * @property {boolean} showPaymentMethods Show the payment methods field.
 * @property {boolean} showMedium Show the "How did you find us" field group.
 * @property {boolean} showRelaxOrWorkQuestion Show the "work or relax" field.
 * @property {boolean} showFormSectionLine Show the `FormSection` separator line.
 * @property {boolean} useGenericTranslation Use generic translations that don't
 * refer to hotels.
 *//**
 * @typedef {Immutable.Map} BookingFormPageDisplayOptions .
 * @property {BookingFormVariants?} formVariant .
 * @property {BookingFormVariants?} sidebarVariant .
 */const bookingFormPageDisplayOptionsPropType=mapContainsExactImmutablePropType({formVariant:PropTypes.oneOf(BOOKING_FORM_VARIANTS_VALUES),sidebarVariant:PropTypes.oneOf(BOOKING_FORM_VARIANTS_VALUES)});

/**
 * @typedef {import('./types').FormDisplayOptions} FormDisplayOptions
 * @typedef {import('./types').BookingFormPageDisplayOptions} BookingFormPageDisplayOptions
 *//**
 * Returns a set of `booleans` that can be used to determine the `UI` of the
 * booking form.
 * @param {BookingFormPageDisplayOptions} bookingFormPageDisplayOptions The options
 * passed to the booking form url query.
 * @return {FormDisplayOptions} .
 */const getFormDisplayOptions=withConformsTo("getFormDisplayOptions",[],a=>{if(!a)return FORM_VARIANT_STANDARD_DISPLAY_OPTIONS;const b=a.get("formVariant");return b===BOOKING_FORM_VARIANTS.LUGGAGE?FORM_VARIANT_LUGGAGE_DISPLAY_OPTIONS:FORM_VARIANT_STANDARD_DISPLAY_OPTIONS});var getFormDisplayOptions$1 = getFormDisplayOptions;

/**
 * Builds form test values of `BookingForm`.
 * @param {Immutable.Map} shoppingCart The `API` response of `shoppingCart`.
 * @param {string?} currentIdPaymentMethod The possible values are `1` and `2`.
 * `1` means "pay at hotel", `2` means pay with `Braintree`.
 * @param {boolean?} isDaybreakHeroSite @example `https://testdevelop.daybreakhotels.com`.
 * @see {@link https://apidev.daybreakhotels.com/v1.0/GB/en-GB/shoppingcarts/2ff5e090-7be4-4726-b769-02d4684dbb14}
 * @return {Immutable.Map} The test values.
 */const buildBookingFormTestValues=withConformsTo("buildBookingFormTestValues",[],(a,b,c)=>{const d=createInitialValues$1({shoppingCart:a,anonymousId:"f5f83e0c-90ca-4fa2-8047-c13182d5fb1c",user:void 0,utmValues:Map({utm_source:"staff",utm_medium:"direct",utm_campaign:"development"}),isDaybreakHeroSite:c}),e=a.get("bookingAttributes"),f=e.get("mandatoryCustomerPhone"),g=e.get("enabledPaymentMethods");let h={firstName:"Sample Name",lastName:"Sample Surname",email:TEST_EMAIL,idPaymentMethod:b};const i=shouldShowBraintreeWidgetSection$1(a,b);if(i){const a=g===ENABLED_PAYMENT_METHODS.CREDIT_CARD_AS_GUARANTEE;h={...h,idPaymentMethod:a?ID_PAYMENT_METHOD_VALUES.PAY_AT_HOTEL:ID_PAYMENT_METHOD_VALUES.BRAINTREE,[BOOKING_FORM_INPUT_NAMES.BRAINTREE_WIDGET_SECTION_VISIBLE]:!0,// @see {@link https://developers.braintreepayments.com/reference/general/testing/dotnet#nonce-fake-valid-visa-nonce}
[BOOKING_FORM_INPUT_NAMES.BRAINTREE_PAYMENT_METHOD_NONCE]:void 0};}return f&&(h={...h,phoneNumber:"011111111111"}),d.merge(fromJS(h))});var buildBookingFormTestValues$1 = buildBookingFormTestValues;

export { bookingFormPageDisplayOptionsPropType, buildBookingFormTestValues$1 as buildBookingFormTestValues, createInitialValues$1 as createInitialValues, getFormDisplayOptions$1 as getFormDisplayOptions, getRequiredFields$1 as getRequiredFields, makeBookingsApiValuesFromBookingFormValues$1 as makeBookingsApiValuesFromBookingFormValues, makeSelectEstimatedArrivalTimeFieldOptions$1 as makeSelectEstimatedArrivalTimeFieldOptions, shouldShowBraintreeWidgetSection$1 as shouldShowBraintreeWidgetSection, shouldShowDbhCCSection$1 as shouldShowDbhCCSection, syncValidate$1 as syncValidate };
