/*
 *
 * `locales`: `@dbh/admindev-constants`.
 *
 */// These countries have `en-GB` locale even though
// the locale in their URL is not that (i.e `AE/ar-AE`).
const COUNTRIES_WITH_FORCED_ENGLISH_LOCALE=Object.freeze({AE:{forcedLocale:"en-GB",realLocale:"ar-AE"}});var COUNTRIES_WITH_FORCED_ENGLISH_LOCALE$1 = COUNTRIES_WITH_FORCED_ENGLISH_LOCALE;

/**
 * Some countries have "forced" en-GB locale (like `AR`); `makeSelectLocale`
 * returns the "forced" locale for them.
 * But we want to use the "real" locale for their generated `API` `URLs`
 * otherwise the `API` will either crash or give an empty result, or know and
 * use their "real" locale in other cases.
 * @param {string} country @example `IT`.
 * @return {boolean} Does the input country have "forced" English locale.
 */const isCountryWithForcedEnglishLocale=a=>Object.keys(COUNTRIES_WITH_FORCED_ENGLISH_LOCALE$1).includes(a);var isCountryWithForcedEnglishLocale$1 = isCountryWithForcedEnglishLocale;

/*
 *
 * Categories: `@dbh/admindev-constants`.
 *
 */// @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/packagecategories}.
// The "macro" categories are those that have not a `parentReference`.
const MACRO_CATEGORIES_REFERENCES=Object.freeze({ROOMS:"RO",POOLS:"SP",MEETING_ROOMS:"MR",SPA:"SPA",APARTMENTS:"AP",RESTAURANTS:"RES",HYDROMASSAGES:"IM",OTHER_SERVICES:"OS",STORAGE_SERVICES:"ST",SMART_WORKING:"SMW",DAY_PASS:"DPS"});const MACRO_CATEGORIES_REFERENCES_VALUES=Object.freeze(Object.values(MACRO_CATEGORIES_REFERENCES));// @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/packagecategories}.
// The "package" categories are those that have a `parentReference`.
// This is a limited use feature.
const PACKAGE_CATEGORIES_REFERENCES=Object.freeze({LUGGAGE_STORAGE_ONE_DAY:"DB24",LUGGAGE_STORAGE_TWO_DAYS:"DB48",SMART_WORKING_ROOM:"SMWR",SMART_WORKING_APARTMENT:"SMWA"});const PACKAGE_CATEGORIES_REFERENCES_VALUES=Object.freeze(Object.values(PACKAGE_CATEGORIES_REFERENCES));

/*
 *
 * `package-groups`: `@dbh/admindev-constants`.
 * @see {@link https://apidev.daybreakhotels.com/v1.0/GB/en-GB/webcontrols/package-categories-groups}
 */const PACKAGE_GROUP_REFERENCES=Object.freeze({ROOMS:"group_rooms",POOLS:"group_pool",MEETING_ROOMS:"group_meeting_rooms",SPA:"group_spa",APARTMENTS:"group_apartment",LUGGAGE_STORAGE:"group_luggage_storage",EXTRAS:"group_extra",RESTAURANTS:"group_restaurant",SMART_WORKING:"group_smart_working",DAY_PASS:"group_day_pass"});const PACKAGE_GROUP_REFERENCES_VALUES=Object.freeze(Object.values(PACKAGE_GROUP_REFERENCES));

/*
 *
 * `pages`: `@dbh/admindev-constants`.
 *
 */// These values can be found in the 'templateCode' value returned by the
// `/webpages` `API`. They must match exactly with the values the `API` returns.
const PAGE_TYPES={HomePage:"HomePage",CategoryPage:"CategoryPage",ShowcasePage:"ShowcasePage",AllCitiesPage:"AllCitiesPage",CityPage:"CityPage",AllPoisPage:"AllPoisPage",PoiPage:"PoiPage",SearchResultsPage:"SearchResultsPage",HdpPage:"HdpPage",PdpPage:"PdpPage",BookingFormPage:"BookingFormPage",ViewFirstReservationPage:"ViewFirstReservationPage",ViewReservationPage:"ViewReservationPage",ConfirmReservationPage:"ConfirmReservationPage",CancelReservationPage:"CancelReservationPage",CategoryCityPage:"CategoryCityPage",PhilosophyAndBenefitsPage:"PhilosophyAndBenefitsPage",NotFoundPage:"NotFoundPage",PostStayRatingPage:"PostStayRatingPage",AffiliationRequestPage:"AffiliationRequestPage",AllHotelsPage:"AllHotelsPage",AllCategoryCityPage:"AllCategoryCityPage",AllCountriesPage:"AllCountriesPage",UserReservationsPage:"UserReservationsPage",ContentPage:"ContentPage",MaintenancePage:"MaintenancePage",ResetPasswordPage:"ResetPasswordPage",ShoppingCartPage:"ShoppingCartPage",FlagContentPage:"FlagContentPage",InvoicesPaymentFormPage:"InvoicesPaymentFormPage",ViewInvoicesPaymentPage:"ViewInvoicesPaymentPage",// Displayed with: EntryContent (a.k.a "popup").
TopMenuHashPage:"TopMenuHashPage",HashPage:"HashPage",// Development only.
DevelopmentOnlyPage:"DevelopmentOnlyPage",DevOnlyMessagesPage:"DevOnlyMessagesPage",DevOnlyChangelogPage:"DevOnlyChangelogPage",TestPage:"TestPage"},PAGE_TYPES_THAT_USE_SEARCH_RESULTS_PAGE_TEMPLATE={[PAGE_TYPES.SearchResultsPage]:PAGE_TYPES.SearchResultsPage,[PAGE_TYPES.CategoryCityPage]:PAGE_TYPES.CategoryCityPage,[PAGE_TYPES.PoiPage]:PAGE_TYPES.PoiPage},SEARCH_RESULTS_PAGE_TYPES=Object.keys(PAGE_TYPES_THAT_USE_SEARCH_RESULTS_PAGE_TEMPLATE);

/*
 *
 * `references`: `@dbh/admindev-constants`.
 *
 *//**
 * These references are hardcoded in the backend for custom business logic
 * related to the loyalty campaign. Do not rename them.
 *
 * For the `/webpages` `API`:
 * - `lp-content-page-not-loyalty-user`: Visible to non-signed-in users and
 * signed-in users not in the loyalty program.
 * - `lp-dashboard-loyalty-user`: Visible only to signed-in users in the loyalty
 * program.
 *
 * For the `/webcontrols` `API`:
 * - `lp-content-page-not-loyalty-user`: Visible to non-signed-in users and
 * signed-in users not in the loyalty program.
 * - `lp-dashboard-loyalty-user`: Visible only to signed-in users in the loyalty
 * program.
 */const LOYALTY_PROGRAM_LOYALTY_USER_DASHBOARD="lp-dashboard-loyalty-user",ADMIN_PAGE_REFERENCES={UserAreaSignIn:"UserAreaSignIn",UserAreaSignUp:"UserAreaSignUp",UserAreaResetPasswordRequest:"UserAreaResetPasswordRequest",UserAreaSignOut:"UserAreaSignOut",UserAreaYourAccount:"UserAreaYourAccount",UserAreaChangePassword:"UserAreaChangePassword",CorporateCompanyInformation:"CorporateCompanyInformation",UserAreaResetPasswordRequestSuccess:"UserAreaResetPasswordRequestSuccess",UserAreaInsertSignUpVerificationCode:"UserAreaInsertSignUpVerificationCode",UserAreaInsertNewUserPassword:"UserAreaInsertNewUserPassword",UserAreaReservationRetrieve:"UserAreaReservationRetrieve",UserAreaCompleteSignUp:"UserAreaCompleteSignUp",Cookies:"cookies",HelpContacts:"helpcontacts",TermsAndConditions:"termsandconditions",Privacy:"privacy",SearchFilters:"SearchFilters",SearchResultsMap:"SearchResultsMap",LoyaltyProgramContentPage:"lp-content-page-not-loyalty-user",LoyaltyProgramLoyaltyUserDashboard:LOYALTY_PROGRAM_LOYALTY_USER_DASHBOARD,HowToEnableJsInBrowser:"HowToEnableJsInBrowser"},ADMIN_PAGE_REFERENCES_ARRAY_FOR_VALIDATION_ONLY=Object.values(ADMIN_PAGE_REFERENCES),ADMINDEV_WEBCONTROLS_REFERENCES=Object.freeze({MENU_TOP:"menu-top",MENU_TOP_CHILDREN:{SIGN_IN:"sign-in",REGISTER:"register",YOUR_BOOKINGS:"your-bookings",COMPANY_INFORMATION:"company-information",YOUR_ACCOUNT:"your-account",SIGN_OUT:"sign-out",// "Help" menu parent.
MENU_HELP:"help-contact",PHONE:"phone",WHATSAPP:"whatsapp",FAQ:"faq",CONTACTS:"contacts",HOTELIER:"hotelier",TOP_DESTINATIONS:"top-destinations",AGENCY_PROGRAM:"agency-program",CORPORATE_PROGRAM:"corporate-program",LOYALTY_PROGRAM_CONTENT_PAGE:"lp-content-page-not-loyalty-user",LOYALTY_PROGRAM_LOYALTY_USER_DASHBOARD,CHANGE_PASSWORD:"change-password",TOP_PAGE_BANNER:"top-page-banner"},BOOKING_INFO:"booking-info",BOOKING_INFO_CHILDREN:{BOOKING_PRICE_INFO:"booking-price-info",BOOKING_TAX_INFO:"booking-price-tax-info"},BANNERS:"banners",LOYALTY_LEVELS_BANNER:"loyalty-levels-banner",HOME_PAGE_BACKGROUND:"HPBackground",SEARCH_CATEGORIES_TREE:"SearchCategoriesTree",FOOTER_ARTICLES:"Articles",WEBSITE_TILES:"CategoriesTree",FOOTER_LAST_BIT:"LastBit",FOOTER_MENU_SOCIAL:"MenuSocial",FOOTER_PARTNERS:"MenuPartners",FOOTER_HOTELS:"HotelsLogos",FOOTER_DAY_USE_CITIES:"footer-seo-links",FOOTER_SEO_TEXT:"footer-seo-text",APP_DOWNLOAD:"app-download",FOOTER_AFFILIATION:"affiliation",PACKAGE_CATEGORIES_GROUPS:"package-categories-groups",NEWSLETTER:"newsletter",FIRM_INFO:"firm-info",IMAGES:"images",// References matched with regular expressions. Please keep them basic.
USECASE:"Usecase-.+?",MAIN_LPCC_LINKS:"main-lpcc-links-.+?",CATEGORIES_TREE_REGEXP:"CategoriesTree-.+?",FLAG_CONTENT_PAGE_SECTION_TITLES:"flag-cp-section-titles-.+?",TOP_CITIES_GENERAL:"TopCitiesGeneral",FAQS:"faqs",STATIC_FRONT_END_FILES:"static-front-end-files",APP_INSTALL_BANNER:"app-install-banner",APP_INSTALL_BANNER_CHILDREN:{DOWNLOAD_APP:"download-app",APPLE_STORE_LINK:"apple-store-link",PLAY_STORE_LINK:"play-store-link"},FLAG_CONTENT_PAGE_LINKS:"flag-cp-links-.+?",TRUSTPILOT_CONFIGURATION:"trustpilot-config"});// A `reference` is not necessarily assigned to a hash page in the `admindev`,

/*
 *
 * `searchContext`: `@dbh/admindev-constants`.
 *
 */const SEARCH_CONTEXT_ID_SEARCH_TYPE=Object.freeze({CITY:1,HOTEL:2,POI:3,PLACE_ID:4,FREE_TEXT:5});const possibleIdSearchType=Object.values(SEARCH_CONTEXT_ID_SEARCH_TYPE);

/*
 *
 * User: `@dbh/admindev-constants`.
 *
 */const USER_PERMISSION_LEVELS={NORMAL_USER:"normalUser",BUSINESS_USER:"businessUser",BUSINESS_ADMIN_USER:"businessAdminUser"};

/*
 *
 * `bookings`: `@dbh/admindev-constants`.
 *
 */const ENABLED_PAYMENT_METHODS=Object.freeze({// No radio button choice component. "Pay Online" (`Braintree`) is HIDDEN.
HOTEL:"H",// Radio button choice component is displayed. Both `Braintree` and pay at hotel
// are shown.
ALL:"A",// No radio button choice component. Only "Pay Online" (`Braintree`) is shown.
PREPAID:"P",// No radio button choice component. Only "Pay Online" (`Braintree`) is shown.
// But `idPaymentMethod` should be "1".
CREDIT_CARD_AS_GUARANTEE:"C",// Radio button choice component is displayed. Both Braintree is shown under
// two options "Pay at hotel" or "Pay Now".
PREPAID_OR_CREDIT_CARD_AS_GUARANTEE:"B"});const PAYMENT_METHODS_REQUIRING_CC=Object.freeze([ENABLED_PAYMENT_METHODS.CREDIT_CARD_AS_GUARANTEE,ENABLED_PAYMENT_METHODS.PREPAID,ENABLED_PAYMENT_METHODS.PREPAID_OR_CREDIT_CARD_AS_GUARANTEE]);const PAYMENT_METHOD_VALUES=Object.values(ENABLED_PAYMENT_METHODS);const PAYMENT_METHODS_REQUIRING_CC_VALUES=Object.values(PAYMENT_METHODS_REQUIRING_CC);const RESERVATION_STATUS={T_MAIL_PENDING:"T",// User that has booked for the first time, still needs to enter the confirmation code.
S_HOTEL_CONFIRMATION_PENDING:"S",// On request booking not confirmed by the hotel. Check also 'codeConfirmed'. It can be TRUE or FALSE.
R_CONFIRMED:"R",// "reserved" === confirmed.
N_NO_SHOW:"N",// "no show", the user hasn't showed up in the hotel.
C_CANCELLED:"C"// "cancelled".
};const RESERVATION_STATUS_VALUES=Object.values(RESERVATION_STATUS);const TIME_SLOT=Object.freeze({day:"D",evening:"E",night:"N"});const TIME_SLOT_VALUES=Object.freeze(Object.values(TIME_SLOT));

/*
 *
 * Package: `@dbh/admindev-constants`.
 * For an unknown reason `babel-loader` loads but doesn't properly transpile
 * `yarn workspaces` packages. Therefore, you cannot use destructuring or any
 * other feature that `IE11` does not support. This module will be converted
 * to `ES6` when the module build system is on `master`.
 */const HASH_PAGE_START="#dbh/",TEST_DEVELOP_ID_HOTEL=2146,ID_PRODUCTS_QUERY_ALL_PACKAGES=7161,DEFAULT_ID_MACRO_CATEGORY=1689,DEFAULT_ID_PRODUCTS_QUERY=2311,HTML_ANCHORS={APP_DOWNLOAD:"#dbh__app-download"};// This is the "test develop" hotel, the only one "safe" to book with
const ROOT_FONT_SIZE_PERCENTAGE="62.5%";const SCROLL_OFFSET_MOBILE=100;// Takes into account the fixed top search bar.
const SCROLL_OFFSET_DESKTOP=201;

export { ADMINDEV_WEBCONTROLS_REFERENCES, ADMIN_PAGE_REFERENCES, ADMIN_PAGE_REFERENCES_ARRAY_FOR_VALIDATION_ONLY, COUNTRIES_WITH_FORCED_ENGLISH_LOCALE$1 as COUNTRIES_WITH_FORCED_ENGLISH_LOCALE, DEFAULT_ID_MACRO_CATEGORY, DEFAULT_ID_PRODUCTS_QUERY, ENABLED_PAYMENT_METHODS, HASH_PAGE_START, HTML_ANCHORS, ID_PRODUCTS_QUERY_ALL_PACKAGES, LOYALTY_PROGRAM_LOYALTY_USER_DASHBOARD, MACRO_CATEGORIES_REFERENCES, MACRO_CATEGORIES_REFERENCES_VALUES, PACKAGE_CATEGORIES_REFERENCES, PACKAGE_CATEGORIES_REFERENCES_VALUES, PACKAGE_GROUP_REFERENCES, PACKAGE_GROUP_REFERENCES_VALUES, PAGE_TYPES, PAGE_TYPES_THAT_USE_SEARCH_RESULTS_PAGE_TEMPLATE, PAYMENT_METHODS_REQUIRING_CC, PAYMENT_METHODS_REQUIRING_CC_VALUES, PAYMENT_METHOD_VALUES, RESERVATION_STATUS, RESERVATION_STATUS_VALUES, ROOT_FONT_SIZE_PERCENTAGE, SCROLL_OFFSET_DESKTOP, SCROLL_OFFSET_MOBILE, SEARCH_CONTEXT_ID_SEARCH_TYPE, SEARCH_RESULTS_PAGE_TYPES, TEST_DEVELOP_ID_HOTEL, TIME_SLOT, TIME_SLOT_VALUES, USER_PERMISSION_LEVELS, isCountryWithForcedEnglishLocale$1 as isCountryWithForcedEnglishLocale, possibleIdSearchType };
